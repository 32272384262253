/* General Styles */
body {
  font-family: 'Open Sans', sans-serif;
  background-color: #87CEEB;
  color: #333;
  margin: 1;
  padding: 0;
  box-sizing: border-box;
}

/* Logo and Header */
.logo {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: auto;
}

.home-page-title {
  text-align: center;
  color: #003366;
  font-family: 'Orbitron', sans-serif;
  margin-top: 10px;
  font-size: 28px;
}

/* Landscape Notice */
.landscape-notice {
  text-align: center;
  color: #ff0000;
  margin: 10px 0;
  font-size: 14px;
}

/* Button Group */
.home-button-group {
  display: flex;
  justify-content: center; /* Centers the button */
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.airfield-state {
  flex: 1; /* Takes up available space on the left */
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
}

button.primary-btn, button.secondary-btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button.primary-btn {
  background-color: #007bff;
  color: white;
  border: none;
}

button.primary-btn:hover {
  background-color: #0056b3;
}

button.secondary-btn {
  background-color: #f44336;
  color: white;
  border: none;
}

button.secondary-btn:hover {
  background-color: #d32f2f;
}

/* Search Fields */
.home-search-fields {
  margin: 20px 0;
  text-align: center;
}

.home-search-fields label {
  font-weight: bold;
  margin-right: 10px;
}

.home-search-fields input {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Table Containers */
.home-table-container {
  margin-top: 20px;
}
/* Table header styling */
.home-table-header {
  display: grid;
  grid-template-columns: 60px 75px 60px 100px 100px 120px 100px 50px; /* Match header structure */
  gap: 1px;
  background-color: #e8e8e8;
  font-weight: bold;
  font-size: 13px; /* Smaller font size */
  padding: 5px;
  border-radius: 5px;
  text-align: left; /* Left-align headers */
}

/* Table grid for data */
.home-table-grid {
  display: grid;
  grid-template-columns: 60px 75px 60px 100px 100px 120px 100px 50px; /* Match header structure */
  gap: 1x;
  font-size: 13px; /* Smaller font size */
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; /* Left-align data */
}

/* Individual table cell */
.home-table-grid div {
  padding: 8px;
  text-align: left; /* Left-align text in cells */
  border-radius: 5px;
  background-color: #f4f4f4;
  overflow: hidden; /* Prevent content overflow */
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}


.home-scrollable {
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* General Buttons */
.green-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.green-btn:hover {
  background-color: #218838;
}

/* Search Input */
.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
}

/* Login Page Styles */
.landing-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #87CEEB; /* Light blue */
  padding: 20px;
  box-sizing: border-box;
}

.overlay {
  max-width: 400px;
  width: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.content h1 {
  font-size: 24px;
  color: #003366;
  font-family: 'Orbitron', sans-serif;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.input-field {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #696969;
  border-radius: 5px;
}

.input-field:focus {
  border-color: #F7C600;
  outline: none;
}

.submit-button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Logo */
.logo {
  max-width: 120px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 20px;
}

.bookin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background-color: #f9f9f9; /* Optional: background color */
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  max-width: 100px;
}


.bookin-form {
  width: 100%;
}

.form-group input {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input::placeholder {
  color: #999;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-group-textfield {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.primary-btn,
.secondary-btn {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primary-btn {
  background-color: #007bff;
  color: white;
}

.secondary-btn {
  background-color: #ccc;
  color: black;
}

.primary-btn:hover {
  background-color: #0056b3;
}

.secondary-btn:hover {
  background-color: #aaa;
}

.account-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-container {
  text-align: center;
  margin-bottom: 10px;
}

.logo {
  width: 100px;
  height: auto;
}


.account-form .form-group {
  margin-bottom: 15px;
}


.form-group-checkbox {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.checkbox-label input[type="checkbox"] {
  margin-right: 8px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}


.admin-home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.admin-home-header {
  text-align: center;
  margin-bottom: 30px;
}

.home-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;  /* Adds spacing between buttons */
}

.dashboard-btn {
  flex: 1;  /* Ensures all buttons take equal width */
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: none;
  max-width: 200px;  /* Sets a maximum width to prevent buttons from becoming too large */
  text-align: center;  /* Ensures text is centered */
}

.dashboard-btn:hover {
  background-color: #0056b3;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;  /* Adds spacing between search field and export button */
}

.search-field {
  width: 70%;
  padding: 10px;
  font-size: 16px;
}

.export-btn {
  width: 28%;
  padding: 12px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
}

.export-btn:hover {
  background-color: #218838;
}

.data-grid-container {
  margin-top: 20px;
  height: 400px;
  width: 100%;
}

.MuiDataGrid-root {
  border: none;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  font-size: 16px;
}

.MuiDataGrid-cell {
  font-size: 14px;
}

.accounts-page-container {
  padding: 20px;
  background-color: #f4f4f4;
}

.accounts-page-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.accounts-search {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.accounts-search input {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.accounts-search button {
  padding: 12px 16px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.accounts-search button:hover {
  background-color: #218838;
}

.accounts-table {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.accounts-table h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.account-row {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
}

.account-row:hover {
  background-color: #e9ecef;
}

.account-row span {
  font-size: 16px;
}

.edit-account {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-account h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.edit-account label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.edit-account input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.edit-account button {
  padding: 12px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.edit-account button:hover {
  background-color: #0056b3;
}
s

.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #87CEEB; /* Light blue */
  padding: 20px;
  box-sizing: border-box;
}

.register-container h1 {
  font-size: 24px;
  color: #003366;
  font-family: 'Orbitron', sans-serif;
  margin-bottom: 20px;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}


.switch-btn {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: grey;
  border-radius: 5px;
}

.switch-btn.active {
  background-color: blue;
}

.status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.status-item {
  font-size: 0.9rem;
  margin-right: 20px;
  color: #333;
}

.status-item:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Darker label for better contrast */
  margin-bottom: 0px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%; /* Ensure fields span the container width */
  padding: 12px; /* Add more padding for a larger clickable area */
  font-size: 16px; /* Keep text size readable */
  border: 2px solid #007BFF; /* Highlighted border */
  border-radius: 5px; /* Softer corners for a modern look */
  background-color: #f9f9f9; /* Subtle background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
  transition: all 0.3s ease; /* Smooth transitions for hover and focus */
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #0056b3; /* Darker border on focus */
  outline: none; /* Remove default outline */
  background-color: #fff; /* Brighter background for focus */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Enhanced shadow on focus */
}

.form-group input::placeholder {
  color: #888; /* Softer placeholder color */
  font-style: italic; /* Distinguish placeholder text */
}

.form-buttons button {
  padding: 12px 20px; /* Larger button sizes */
  font-size: 16px; /* Readable text */
  font-weight: bold; /* Emphasize buttons */
  border: none; /* Remove borders */
  border-radius: 5px; /* Match input styles */
  cursor: pointer; /* Indicate clickability */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.form-buttons .primary-btn {
  background-color: #007BFF; /* Primary color */
  color: #fff; /* Contrast text */
}

.form-buttons .primary-btn:hover {
  background-color: #0056b3; /* Darker hover color */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.form-buttons .secondary-btn {
  background-color: #f8f9fa; /* Light secondary color */
  color: #333; /* Darker text for contrast */
  border: 1px solid #ccc; /* Subtle border */
}

.form-buttons .secondary-btn:hover {
  background-color: #e2e6ea; /* Slightly darker hover */
}

.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.home-button-group {
  display: flex;
  flex-direction: column;  /* Stack buttons on small screens */
  align-items: center;
  width: 100%;
  max-width: 500px;  /* Limit width on larger screens */
  text-align: center;
}

.home-button-group p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.5;
  text-align: center;
}

.home-button-group button {
  width: 100%;  /* Full width on mobile */
  max-width: 300px;  /* Limit width on larger screens */
  margin-bottom: 0.5rem;
}

@media (min-width: 600px) {
  .home-button-group {
    flex-direction: row;  /* Side by side on bigger screens */
    justify-content: center;
  }

  .home-button-group button {
    margin-left: 1rem;
    margin-bottom: 0; /* Remove extra spacing on larger screens */
  }
}


