/* Booking Page Styles */
.booking-container {
    padding: 1rem;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .booking-container h1 {
    font-size: 1.8rem;
    text-align: center;
  }
  
  .booking-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-wrap: wrap;
  }

  .form-group date-time-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .form-group.full-width {
    flex-direction: column;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .switch-btn {
    flex: 1;
    padding: 0.75rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .switch-btn.active {
    background-color: #007bff;
    color: white;
  }
  
  input[type="date"],
  input[type="time"],
  select,
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  button.primary-btn {
    background-color: #28a745;
    color: white;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button.secondary-btn {
    background-color: #6c757d;
    color: white;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button.primary-btn:hover {
    background-color: #218838;
  }
  
  button.secondary-btn:hover {
    background-color: #5a6268;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-group {
      flex-direction: row;
    }
  }
  
  .radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  
  